<template>
    <!-- 商品，认养、土地营销设置 -->
    <div class="com-account-level-set" v-if="levelForm">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
            <a-form-item>
                <template #label>
                    <span>购买赠送积分</span>
                    <a-tooltip title="用户购买该商品可获得的积分数量">
                        <i class="ri-information-fill"></i>
                    </a-tooltip>
                </template>
                <a-input v-model:value="levelForm.score" addon-after="积分"></a-input>
            </a-form-item>
            <a-form-item label="会员折扣">
                <a-radio-group v-model:value="levelForm.is_user_discount">
                    <a-radio :value="0">关闭</a-radio>
                    <a-radio :value="1">
                        开启（默认折扣）
                        <a-tooltip title="按会员等级折扣优惠">
                            <i class="ri-information-fill ri-top"></i>
                        </a-tooltip>
                    </a-radio>
                    <a-radio :value="2">开启（指定折扣）</a-radio>
                </a-radio-group>
            </a-form-item>
            <div v-if="levelForm.is_user_discount == 2">
                <a-form-item label="额外优惠金额">
                    <a-input v-model:value="levelForm.specify_discount_price" addon-after="元"></a-input>
                    <div class="f12 lh16">商品最终价格 = 商品原价-额外优惠金额</div>
                </a-form-item>	
                <a-form-item label="生效等级">
                    <a-checkbox-group v-model:value="levelForm.levels">
                        <a-checkbox v-for="(item,index) in userLevel" :key="index" :value="item.id">
                            {{item.level_name}}
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-item>	
            </div>
		</a-form>
    </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import userModel from '@/api/user'
export default {
    name:"ComAccountLevelForm",
    props:{
        formData:{
            type:[Array,Object]
        }
    },
    setup(props,context) {
        const state = reactive({
            userLevel:[],
            levelForm: props.levelForm
        })

        state.levelForm = props.formData
        
        // const checkAttr = computed(() => {
        //     return function(attr){
        //         let attrs = Object.keys(props.formData)
        //         if( attrs.includes(attr) ) return true
        //         return false
        //     }
        // })

        userModel.getAccountLevel(1,999,res=>state.userLevel = res.list)

        watch(() => state.levelForm,(value) => {
            context.emit("success",value)
        },{ deep: true });

        watch(()=>props.formData,(value)=>{
            state.levelForm = value
        },{deep:true})

        return{
            ...toRefs(state),
            // checkAttr
        }
    },
}
</script>
